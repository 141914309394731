<template>
  <div>
    <div class="topbox">
          <div class="topvideo" style="height: 492px; width:1200px">
            <el-carousel height="492px">
              <!-- <el-carousel-item v-for="item in 4" :key="item"> -->
                <el-carousel-item v-for="(item, index) in bannerList" :key="index" @click.native="topage(item.h5Url)">
                  <h3 class="small">
                    <img :src="item.bannerImg" width="100%" height="100%" alt />
                  </h3>
                </el-carousel-item>
              <!-- </el-carousel-item> -->
            </el-carousel>
          </div>
          <!-- <div class="zzbox"> -->
              <!-- <div class="ztitle"><span>最热问答</span></div>
              <small-videos-list :videos="videos" class="smallvideo"></small-videos-list> -->
          <!-- </div> -->
    </div>
    <!-- <choose-dis-type :distype="type" class="choose"></choose-dis-type> -->
    <!-- <choose-disnow class="choose" :distype="type"></choose-disnow> -->
    <choose-disnow class="choose"></choose-disnow>
    <div class="biaoti" style="width: 300px">
      <recommend-title :Title="'问答中心'" :eTitle="'Featured Articles'" :color="'#2AC769'" @paixu="paixu"  class="rectitle"></recommend-title>
    </div>
      <div class="msbox">
          <el-row class="row">
            <el-col :span="24" v-for="project in question" :key="project.pid" :offset="1">
                <long-question-answertest :question-item="project" :type="'long'" class="now"></long-question-answertest>
            </el-col>
          </el-row>
      </div>
      <div>
        <bottom-bar class="bottom"></bottom-bar>
      </div>
  </div>
</template>

<script>
// import SmallVideosList from 'components/content/videos/SmallVideosList'
// import ChooseDisType from 'components/content/choose/ChooseDisType'
import ChooseDisnow from 'components/common/choosedis/ChooseDisnow'
import RecommendTitle from 'components/content/recommendtitle/RecommendTitle'
// import LongQuestionAnswerList from 'components/content/questionanswer/LongQuestionAnswerList'
import LongQuestionAnswertest from 'components/content/questionanswer/LongQuestionAnswertest'
import { getdetailList, getBanner } from 'network/getData'
import BottomBar from 'components/content/bottombar/BottomBar'
export default {
  name: 'Video',
  components: {
    // SmallVideosList,
    // ChooseDisType,
    RecommendTitle,
    // LongQuestionAnswerList,
    LongQuestionAnswertest,
    ChooseDisnow,
    BottomBar
  },
  data () {
    return {
      question: [],
      videos: [
      ],
      bannerList: [],
      types: ['latest', 'popular']
    }
  },
  // metaInfo () {
  //   return {
  //     meta: [
  //       { name: 'keywords', content: '科普视频,专家文章,专家音频,名医问答,查疾病,瑞尔安心、瑞尔良医、瑞尔良医app、瑞尔安心官网、瑞尔视景、瑞尔视景科技有限公司、单道动态心电记录仪、7天可穿戴心电监测设备、智能心电监测设备' },
  //       { name: 'description', content: '瑞尔安心在线平台,专家,疾病,科室等详细信息,由专家生产的科普视频,文章,音频等内容,瑞尔安心平台向全民提供“科普视频+语音问答+图文咨询+文章+大数据分析”多位一体的精准健康科普知识，满足大众多样化、多场景应用的健康科普需求。致力于通过互联网技术推动医生共享、知识共享、服务共享、经济共享，放大医生价值和健康科普内容价值，用通俗易懂的方式以及直白语言，制作出大众听得懂、记得住、学得会、用得上的健康科普知识，切实提升公众健康素养，让更多人能够健康地享受美好生活，助力全民健康。研发出多款医疗器械，实现了心脑血管病患者、高危人群得日常疾病筛查和健康管理，并配套了全程专业的健康管理服务，在国内医疗产品行业中具有很高的地位和影响。公司主要从事公司下设医学研发中心、软件开发中心、生产制造中心、营销中心、医学服务中心。' }
  //     ]
  //   }
  // },
  methods: {
    paixu (val) {
      // console.log(val)
      this.getdetailListdata(20, 1, this.types[val], 'qa', '')
    },
    async getdetailListdata (rows, page, filterCondition, type, infoTag) {
      try {
        const result = await getdetailList(rows, page, filterCondition, type, infoTag)
        this.question = result.data.data
        // console.log(this.question, 'question1111111111')
      } catch (error) {
        // console.log(error)
      }
    },
    topage (e) {
      const asd = e.split('|')
      if (asd[1] === 'video') {
        // sessionStorage.setItem('nav', '2')
        sessionStorage.setItem('id', asd[0])
        this.$router.push({ path: '/mobVideoCenterDel', query: { videoId: asd[0] } })
      } else if (asd[1] === 'audio') {
        // sessionStorage.setItem('nav', '3')
        sessionStorage.setItem('id', asd[0])
        this.$router.push({ path: '/mobVideoCenterAudioDel', query: { audioId: asd[0] } })
      } else if (asd[1] === 'article') {
        // sessionStorage.setItem('nav', '4')
        sessionStorage.setItem('id', asd[0])
        this.$router.push({ path: '/mobVideoCenterArticleDel', query: { articleId: asd[0] } })
      } else if (asd[1] === 'qa') {
        // sessionStorage.setItem('nav', '5')
        this.$router.push({ path: '/mobQuestionCenterDel', query: { qaId: asd[0] } })
      } else if (e === 'empty') {
        return ''
      } else {
        window.open(e)
      }
    },
    async getBannerList (type) {
      try {
        const result = await getBanner(type)
        this.bannerList = result.data.data
        // console.log('this.bannerList', this.bannerList)
      } catch (error) {
        // console.log(error)
      }
    }
  },
  mounted () {
    this.getBannerList('qa')
    this.getdetailListdata(20, 1, 'latest', 'qa', '')
  }
}
</script>

<style scoped>
@import url("~assets/css/cc.css");
.row{
  position: relative;
  top: 40px;
}
.el-col-offset-1{
  margin-left: 0%;
}
.el-col :hover{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.now :hover{
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
}
</style>
